<template>
	<div class="container">
		<header-view ref="header" :class="{'on': minBar}" :showMenu="showMenu" :page="4" @change="changeMenu"></header-view>
		<right-tool :class="{'show': btnFlag}" :isEmit="true" @topTap="topTap"></right-tool>
		<div class="full-wrap">
			<full-page :options="options" id="fullpage" ref="fullpage">
				<div class="section">
					<div class="pic">
						<img class="animated picscall" src="../../assets/images/a-banner.png" />
					</div>
					<div class="ind_hint_wrap flex-col">
						<div class="ind_hint_icon animate__animated animate__fadeInUp" style="animation-delay: .2s;"><div></div></div>
						<div class="ind_hint ft-12 animate__animated animate__fadeInUp" style="animation-delay: .4s;">SCROLL DOWN</div>
					</div>
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-64 animate__animated animate__fadeInDown" style="visibility: visible; animation-delay: .2s;">
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0s;">想</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0.1s;">得</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0.2s;">到</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0.3s;">,</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0.4s;">做</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0.5s;">得</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0.6s;">到</em>
								</span>
							</h1>
							<small class="ft-20 animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: .6s;">
								<span>
									<em class="animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: 0s;">We</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: 0.2s;">can</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: 0.4s;">do</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: 0.6s;">what</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: 0.8s;">we</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: 1s;">want</em>
								</span>
							</small>
						</div>
					</div>
				</div>
				<div class="section other">
					<div class="topic">
						<div class="company-profile flex-row">
							<div class="left animate__animated">
								<img src="../../assets/images/company.png" />
							</div>
							<div class="right flex-col animate__animated t-left">
								<h1 class="ft-34">公司简介</h1>
								<p class="p1"><span class="ft-22">浩亿影业集团有限公司</span><span class="ft-20">成立于<em class="ft-36">2015</em>年</span></p>
								<p class="p2 ft-16">总部设立于美丽的鹭岛厦门市，坐落在世界上独一无二的水上大观园厦门园博苑湖畔的厦门杏林湾商务营运中心，注册资本超过三千万，是一家从事影视出品、影视拍摄与制作、影视宣发、影视数据库管理为一体的综合性企业。 </p>
								<p class=" ft-16">我们拥有深谙影视市场的专业化影视项目开发、影视项目整合、影视项目推广团队，对影视市场有较敏锐的洞察力。</p>
							</div>
						</div>
					</div>
					<div class="pic">
						<img src="../../assets/images/a-banner1.png" />
					</div>
				</div>
				<div class="section other">
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-42 animate__animated" style="animation-delay: 0s!important;">
								<span>
									<em>企</em>
								</span>
								<span>
									<em>业</em>
								</span>
								<span>
									<em>理</em>
								</span>
								<span>
									<em>念</em>
								</span>
							</h1>
						</div>
						<div class="item-wrap culture">
							<div class="item-list t4 flex-row">
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/idea1.png" />
									</div>
									<div class="text animate__animated">
										<h1 class="ft-24">定位</h1>
										<p class="ft-14">以前瞻性的视角整合全球资源，</p>
										<p class="ft-14">推动影视行业快速发展</p>
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/idea2.png" />
									</div>
									<div class="text animate__animated">
										<h1 class="ft-24">使命</h1>
										<p class="ft-14">让内容不断创新，制造不断优化，</p>
										<p class="ft-14">提供优质文化传播</p>
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/idea3.png" />
									</div>
									<div class="text animate__animated">
										<h1 class="ft-24">愿景</h1>
										<p class="ft-14">打造影视行业领域的权威品牌</p>
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/idea4.png" />
									</div>
									<div class="text animate__animated">
										<h1 class="ft-24">团队</h1>
										<p class="ft-14">树立全局观念，诚实守信、 </p>
										<p class="ft-14">融合共赢</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pic">
						<img src="../../assets/images/a-banner2.png" />
					</div>
				</div>
				<div class="section other">
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-42 animate__animated" style="animation-delay: 0s!important;">
								<span>
									<em>战</em>
								</span>
								<span>
									<em>略</em>
								</span>
								<span>
									<em>与</em>
								</span>
								<span>
									<em>布</em>
								</span>
								<span>
									<em>局</em>
								</span>
							</h1>
							<div class="details ft-16 animate__animated" style="animation-delay: .1s!important;">
								<p>目前公司在影视行业里面主要攻略的是影视出品、影视拍摄与制作、影视宣发、数据库管理等综合性项目。</p>
							</div>
						</div>
						<div class="item-wrap panel">
							<div class="item-list panel-list flex-row">
								<div class="item flex-col animate__animated">
									<h1 class="ft-24">公司业务</h1>
									<ul class="t-left flex-col">
										<li class="ft-16">
											<p>影视娱乐板块</p>
											<p>电影、电视剧、艺人经纪</p>
										</li>
										<li class="ft-16">
											<p>影视行业板块</p>
											<p>影视出品、影视拍摄与制作、</p>
											<p>影视宣发、数据库管理</p>
										</li>
									</ul>
								</div>
								<div class="item flex-col animate__animated">
									<h1 class="ft-24">三大发展战略</h1>
									<ul class="t-left flex-col">
										<li class="ft-16">
											<p>影视娱乐生态圈战略</p>
										</li>
										<li class="ft-16">
											<p>高效的内容平台战略</p>
										</li>
										<li class="ft-16">
											<p>国内市场战略</p>
										</li>
									</ul>
								</div>
								<div class="item grow2 flex-col animate__animated">
									<h1 class="ft-24">我们的使命</h1>
									<ul class="t-left flex-col">
										<li class="ft-16">
											<p>以提供优质的影视内容为核心，打造IP内容驱动的影视产业链系统</p>
										</li>
										<li class="ft-16">
											<p>致力于IP内容的价值最大化和流量转化最大化，公司将全力打造国</p>
											<p>内“影视业的创新驿站”， </p>
										</li>
										<li class="ft-16">
											<p>以影视产品为媒介助力中国文化走向世界</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="pic">
						<img src="../../assets/images/a-banner3.png" />
					</div>
				</div>
				<div class="section other contact_footer">
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-42 animate__animated" style="animation-delay: 0s!important;">
								<span>
									<em>联</em>
								</span>
								<span>
									<em>系</em>
								</span>
								<span>
									<em>我</em>
								</span>
								<span>
									<em>们</em>
								</span>
							</h1>
							<div class="tel-wrap flex-row">
								<div class="tel-item t-left animate__animated">
									<baidu-map class="map" :center="markerPoint" :zoom="18" :mapStyle="mapStyle" :autoLocation="true">
										<bm-marker :position="markerPoint" :dragging="true" @click="infoWindowOpen" style="background-color: #151515;">
											<bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen" style="font-size:12px">
												<div class="info-window">
													<span style="font-size:14px;color: #EA4738;font-weight: bold;">厦门浩亿影业集团有限公司</span><br><br>
													<span>地址：福建省厦门市集美区杏林湾商务营运中心11号楼12层</span>													
												</div>
											</bm-info-window>
										</bm-marker>
									</baidu-map>
								</div>
								<div class="tel-item t-right flex-col animate__animated">
									<div class="row flex-row animate__animated">
										<div class="icon flex-row">
											<img src="../../assets/images/address.png" />
										</div>
										<div class="text-wrap flex-col">
											<p class="ft-16">Address</p>
											<p class="ft-18">福建省厦门市集美区杏林湾商务营运中心11号楼12层</p>
										</div>
									</div>
									<div class="row flex-row animate__animated">
										<div class="icon flex-row">
											<img src="../../assets/images/zipcode.png" />
										</div>
										<div class="text-wrap flex-col">
											<p class="ft-16">Zip Code</p>
											<p class="ft-18">361022</p>
										</div>
									</div>
									<div class="row flex-row animate__animated">
										<div class="icon flex-row">
											<img src="../../assets/images/phone.png" />
										</div>
										<div class="text-wrap flex-col">
											<p class="ft-16">Phone</p>
											<p class="ft-18">15280605328</p>
										</div>
									</div>
									<div class="row flex-row animate__animated">
										<div class="icon flex-row">
											<img src="../../assets/images/email.png" />
										</div>
										<div class="text-wrap flex-col">
											<p class="ft-16">Email</p>
											<p class="ft-18">2545817883@qq.com</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pic">
						<img src="../../assets/images/a-banner4.png" />
					</div>
					<div class="bottom">
						<div class="w80 flex-row animate__animated">
							<p class="ft-12">浩亿影业 : www.haoyifilmgroup.com 版权所有 | <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备18025946号-1</a></p>
						</div>
					</div>
				</div>
			</full-page>
		</div>
	</div>
</template>
<script>
	import headerView from '@/components/header/headerView.vue'
	import rightTool from '@/components/rightTool/index.vue'
	import $ from 'jquery'
	export default {
		components: {
			headerView,
			rightTool
		},
		data() {
			return {
				showMenu: false,
				minBar: false,
				btnFlag: false,
				markerPoint:{lng: 118.072213, lat: 24.599821},
				show:false,
				mapStyle: {
					style: 'dark',
				},
				options: {
					licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
					afterLoad: this.afterLoad,
					navigation: true,
					scrollingSpeed: 1000,
					css3: true,
				}
			};
		},
		methods: {
			changeMenu(bool) {
				this.showMenu = !bool
			},
			topTap() {
				this.$refs.fullpage.api.moveTo(1)
			},
			infoWindowClose () {
				this.show = false
			},
			infoWindowOpen () {
				this.show = true
			},
			afterLoad(anchorLink, index) {
				if (index.index === 0) {
					this.showMenu = false
				} else {
					this.showMenu = true
				}
				if ($(index.item).hasClass('active') && index.index !== 0) {
					this.btnFlag = true
					this.setAnimate('.section.active .topic .animate__animated','animate__fadeInUp')
					this.setAnimate('.section.active.contact_footer .animate__animated','animate__fadeInUp')
				} else {
					this.btnFlag = false
				}
			},
			setAnimate(dom,animateName) {
				if (!$(dom).length) return
				if ($(dom).length <= 1) {
					let domHeight = $(window).height() - ($(dom).offset().top - $(document).scrollTop()) - $(dom).height()
					if (($(window).height() - domHeight) > $(window).height() * 0.5) {
						$(dom).css("visibility","visible");
						$(dom).addClass(animateName)
					}					
				} else {
					var domList = $(dom)
					let domHeight = [];
					for(let i = 0; i < domList.length; i++) {
						domHeight[i] = $(window).height() - ($(domList[i]).offset().top - $(document).scrollTop()) - $(domList[i]).height()
						// console.log($(window).height() + domHeight[i])
						// if (($(window).height() + domHeight[i]) > $(window).height() * 0.5) {
							if ((i+1) < 10) {
								$(domList[i]).css({"visibility":"visible","animation-delay": "0."+(i+1)+"s"});
							} else {
								$(domList[i]).css({"visibility":"visible","animation-delay": "1."+(i+1)+"s"});
							}
							$(domList[i]).addClass(animateName)
						// }	
					}
				}
			},
		}
	};
</script>
<style lang="scss" scoped>
	.container{
		.full-wrap{
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			.fullpage-wrapper{
				width: 100%;
				height: 100vh!important;
				.section{
					position: relative;
					width: 100%;
					height: 100%;
					&.contact_footer{
						/deep/ .fp-tableCell{
							.pic{
								position: absolute;
								width: 100%;
								height: calc(100% - 8vh)!important;
								left: 0;
								top: 0;
								img{
									display: block;
									width: 100%;
									height: 100%;
									object-fit: cover;
									display: block;
									transition: all 3s ease;
								}
							}
							.topic{
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: calc(100% - 8vh);
							}
							.bottom {
								position: absolute;
								bottom: 0;
								width: 100%;
								height: 8vh;
								visibility: hidden;
								&::before {
									position: absolute;
									left: 0;
									top: 0;
									content: '';
									width: 100%;
									height: 1px;
									background-color: #3F4D6C;
									opacity: 0.2477;
								}
							
								.w80 {
									width: 80%;
									margin: 0 auto;
									height: 100%;
									justify-content: flex-start;
							
									p {
										color: #6B6B6B;
										animation-delay: .8s;
										a{
											color: #6B6B6B;
										}
									}
								}
							}
						}
					}
					/deep/ .fp-tableCell{
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%!important;
						display: flex;
						justify-content: center;
						align-items: center;
						.pic{
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							overflow: hidden;
							img{
								display: block;
								width: 100%;
								height: 100%;
								object-fit: cover;
								display: block;
								transition: all 3s ease;
							}
						}
						.ind_hint_wrap{
							position: absolute;
							bottom: 0;
							left: 0;
							padding: 30px 0;
							width: 100%;
							.ind_hint{
								color: #FFFFFF;
							}
							.ind_hint_icon{
								position: relative;
								width: 16px;
								height: 26px;
								border: 2px solid #fff;
								border-radius: 8px;
								padding: 4px 0;
								margin-bottom: 10px;
								div{
									height: 6px;
									width: 2px;
									background-color: #fff;
									border-radius: 2px;
									position: absolute;
									top: 6px;
									left: 50%;
									margin-left: -1px;
									animation: gun0 1s ease infinite;
								}
							}
						}
						.topic {
							position: relative;
							z-index: 10;
							width: 100%;
							text-align: center;
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction: column;
							.animate__animated{
								color: #fff;
								visibility: hidden;
							}
							.company-profile{
								width: 80vw;
								.right{
									width: 50%;
									margin-left: 5%;
									padding: 10px 0;
									justify-content: space-between;
									align-items: flex-start;
									h1{
										margin-bottom: 3vh;
									}
									.p1,.p2{
										margin-bottom: 2vh;
										line-height: 28px;
									}
									.p1{
										.ft-20{
											margin-left: 3vw;
										}
									}
								}
								.left{
									width: 45%;
									img{
										width: 100%;
									}
								}
							}
							.txt_ {
								min-width: 50vw;
								max-width: 70vw;
								margin-bottom: 2vh;
								h1 {
									line-height: 60px;
									letter-spacing: 4px;
									span > em {
										font-weight: 400;
									}
								}
								small {
									display: block;
									line-height: 40px;
									height: 40px;
									letter-spacing: 1px;
									span {
										margin: 0 4px;
										font-size: inherit;
										color: inherit;
										font-weight: inherit;
										font-family: inherit;
										line-height: 1.2;
										overflow: hidden;
										vertical-align: middle;
									}
								}
								.details{
									margin-top: 5vh;
									p{
										line-height: 28px;
									}
								}
								.tel-wrap{
									width: 75vw;
									margin-top: 10vh;
									justify-content: space-between;
									color: #FFF;
									.tel-item{
										&.t-left{
											width: 52%;
											height: 50vh;
											background-color: #FFF;
											.map{
												width: 100%;
												height: 100%;
											}
										}
										&.t-right{
											width: 45%;
											height: 50vh;
											justify-content: space-between;
											.row{
												width: 100%;
												height: 25%;
												justify-content: flex-start;
												visibility: hidden;
												.icon{
													height: 100%;
													img{
														width: 2.5vw;
													}
												}
												.text-wrap{
													position: relative;
													width: calc(100% - 4vw);
													height: 100%;
													align-items: flex-start;
													padding: 2.5vh 0;
													margin-left: 1vw;
													&::after{
														content: '';
														width: 100%;
														height: 1px;
														background-color: rgba(255, 255, 255, .6);
														transform: scaleY(0.3);
														position: absolute;
														bottom: 0;
														left: 0;
													}
													.ft-18{
														margin-top: 4px;
														text-align: left;
													}
												}
												&:first-child{
													.text-wrap{
														padding-top: 0.5vh;
													}
												}
												&:last-child{
													.text-wrap{
														padding-bottom: 0.5vh;
														&::after{
															background-color: transparent;
														}														
													}
												}
											}
										}
									}
									
								}
							}
							.item-wrap{
								margin-top: 5vh;
								min-width: 60vw;
								max-width: 70vw;
								color: #FFF;
								&.culture{
									width: 80vw;
									min-width: 60vw;
									max-width: 80vw;
									margin-top: 20vh;
								}
								&.panel{
									width: 80vw;
									max-width: 80vw;
									margin-top: 10vh;
								}
								.item-title{
									margin-bottom: 6vh;
									span{
										position: relative;
										padding: 0 20px;
										&::before,&::after{
											content: '';
											width: 70px;
											height: 1px;
											background-color: rgba(255, 255, 255, .6);
											position: absolute;
											top: 50%;
										}
										&::before{
											left: -80px;
										}
										&::after{
											right: -80px;
										}
									}
								}
								.item-list{
									&.panel-list{
										justify-content: space-between;
										align-items: stretch;
										.item{
											width: 24%;
											// min-height: 42vh;
											background: rgba(255,255,255,0.0875);
											border-radius: 20px;
											padding: 4vw 0;
											margin-left: 3%;
											justify-content: flex-start;
											&:first-child{
												margin-left: 0;
											}
											&:last-child{
												width: 48%;
											}
											h1{
												width: 100%;
												margin-bottom: 3vh;
											}
											ul{
												align-items: flex-start;
												padding-left: 1vw;
												li{
													list-style-type:initial;
													// margin-bottom: 10px;
													p{
														line-height: 28px;
													}
												}
											}
										}
									}
									&.t1{
										justify-content: space-around;
										.item{
											justify-content: flex-start;
											width: 30%;
											.top{
												width: 8vw;
												height: 8vw;
												border: 2px dashed rgba(255,255,255,.7);
												border-radius: 50%;
												margin-bottom: 35px;
												visibility: hidden;
												img{
													width: 4vw;
												}
											}
											.text{
												visibility: hidden;
												h1{
													margin-bottom: 1vh;
												}
												p{
													line-height: 22px;
												}
											}
										}
									}
									&.t2{
										flex-wrap: wrap;
										justify-content: flex-start;
										.item{
											width: 25%;
											margin-bottom: 2vh;
											.top{
												img{
													width: 8vw;
												}
											}
										}
									}
									&.t3{
										flex-wrap: wrap;
										justify-content: flex-start;
										.item{
											width: 25%;
											margin-bottom: 3vh;
											.top{
												width: 80%;
												img{
													width: 100%;
												}
											}
										}
									}
									&.t4{
										align-items: flex-start;
										.item{
											justify-content: flex-start;
											width: 25%;
											.top{
												width: 12vw;
												visibility: hidden;
												img{
													width: 7vw;
												}
											}
											.text{
												visibility: hidden;
												h1{
													margin: 3vh 0 1vh 0;
												}
												p{
													line-height: 22px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>